import React from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'gatsby'

import Layout from '../../layout/layout'

import Statistics from '../../svg/statistics.svg'
import Collaboration from '../../svg/retailer-collaboration.svg'

const Page = () => (
        <Layout>

            <Helmet>
                <title>Datakoppeling - Makkelijk productinformatie ontsluiten</title>
                <meta name="description" content="Het Zupr platform ontsluit productinformatie richting de consument. Deze productinformatie is in de meeste gevallen direct afkomstig van de merken, maar soms ook rechtstreeks van retailers." />
            </Helmet>

            <section className="text-default">
                <div className="inner">
                    <div className="text">
                        <h2>Datakoppeling</h2>
                        <p>Het Zupr platform ontsluit productinformatie richting de consument. Deze productinformatie is in de meeste gevallen direct afkomstig van merken, maar soms ook rechtstreeks van retailers. Aangezien het Zupr platform opgezet is als API, biedt deze ook de mogelijkheid om direct met het platform te koppelen.</p>
                        <h3>Hoe lever ik productdata aan?</h3>
                        <p>Deze pagina beschrijft de wijze waarop data aangeleverd dienen te worden. Deze pagina is geschreven voor merken. Als u als retailer uw assortiment wilt koppelen, kijk dan op de <a href="/nl/quickstart/">quickstart pagina</a>.</p>
                    </div>
                </div>
            </section>

            <section className="text-left grey divider image-bottom">
                <div className="inner">
                    <div className="text">
                        <h2>Externe bronnen</h2>
                        <p>Zupr koppelt reeds met de volgende bronnen*:
                          <ul className="check-list">
                            <li><a href="https://www.gs1.nl/sectorafspraken-over-standaarden/betrouwbare-productdata-levensmiddelen-en-drogisterij-agf-en/gs1-13">GS1 datalink</a></li>
                            <li>FashionCloud</li>
                            <li>Channable</li>
                            <li>Icecat</li>
                            <li>Centraal boekhuis en Titelbank</li>
                          </ul>
                          <small>* Deze bronnen worden continue aangevuld</small>
                        </p>
                    </div>
                    <div className="image">
                        <Collaboration />
                    </div>
                </div>
            </section>

            <section className="text-default grey">
                <div className="inner">
                    <div className="text">
                        <h3>Productinformatie</h3>
                        <p>Indien uw productinformatie momenteel in een van deze externe databronnen opgeslagen is, is deze waarschijnlijk al aanwezig in het Zupr platform of kan deze eenvoudig toegevoegd worden.</p>
                        <p>Mochten uw data niet aanwezig zijn in het Zupr platform, dan is de eenvoudigste manier om deze beschikbaar te maken op een URL, zodat deze door Zupr periodiek geüpdatet kan worden. Een voorbeeld hiervan is het Channable systeem of andersoortige PIM systemen. In overleg kunnen data aangeleverd  worden in Excel (.csv) of in het JSON formaat.</p>
                    </div>
                </div>
            </section>

            <section className="text-default divider">
                <div className="inner">
                    <div className="text">
                        <h2>Geverifieerde productinformatie</h2>
                        <p>Uiteraard is het voor u als merk een service richting de retailer om de productinformatie binnen Zupr beschikbaar te maken. Op deze manier heeft een merk als eigenaar zelf de controle over de data en krijgt deze tevens het label geverifieerd. Wanneer het merk geen data aanlevert is het bij Zupr ook mogelijk dat een retailer zelf deze productinformatie aanlevert. Lees <Link to="/nl/merken/">hier</Link> wat de overige voordelen zijn om u als merk aan te melden.</p>
                    </div>
                </div>
            </section>

            <section className="text-default divider">
                <div className="inner">
                    <div className="text">
                        <h2>Welke data zijn relevant?</h2>
                        <p>Op dit moment ondersteunt Zupr de productgroepen <i>Schoeisel</i>, <i>Kleding</i>, <i>Voeding</i> en <i>Overig</i>. Het voorbeeld hieronder toont de relevante basis-data van een product. Kijk hieronder voor een toelichting voor <i>Kleding</i>, <i>Schoeisel</i> en <i>Voeding</i>.</p>
                        <div className="table">
                            <table>
                              <tr>
                                <th></th>
                                <th>Type</th>
                                <th>Voorbeeld</th>
                                <th>Vereist</th>
                                <th>Opmerking</th>
                              </tr>
                              <tr>
                                <th>language</th>
                                <td>string</td>
                                <td>nl</td>
                                <td>nee</td>
                                <td>De taalkeuze is relevant voor oa. de titel, beschrijving en de advies-verkoop-prijs. ISO (nl, de, fr, ...)</td>
                              </tr>
                              <tr>
                                <th>barcode</th>
                                <td>string</td>
                                <td>870000000001</td>
                                <td>ja</td>
                                <td>GTIN / EAN (13)</td>
                              </tr>
                              <tr>
                                <th>type</th>
                                <td>string</td>
                                <td>footwear</td>
                                <td>nee</td>
                                <td>opties: footwear, fashion, food, other</td>
                              </tr>
                              <tr>
                                <th>classificatie</th>
                                <td>string</td>
                                <td>sneakers</td>
                                <td>nee</td>
                                <td>vb: sneakers, shirts, ...</td>
                              </tr>
                              <tr>
                                <th>title</th>
                                <td>string</td>
                                <td>MyProduct1</td>
                                <td>ja</td>
                                <td>De merknaam maakt geen onderdeel uit van de productnaam.</td>
                              </tr>
                              <tr>
                                <th>brand</th>
                                <td>string</td>
                                <td>MyBrand</td>
                                <td>ja</td>
                                <td>Binnen een dataset zijn producten van meerdere merken mogelijk.</td>
                              </tr>
                              <tr>
                                <th>description</th>
                                <td>string</td>
                                <td>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</td>
                                <td>nee</td>
                                <td>Ondanks dat deze kolom niet vereist is, verbeterd het vermelden van een beschrijving de relevantie van de resultaten voor de gebruiker.</td>
                              </tr>
                              <tr>
                                <th>images</th>
                                <td>list</td>
                                <td>['https://domain.tld/imgs/prod1a.jpg', 'https://domain.tld/imgs/prod1b.jpg', ...]</td>
                                <td>nee</td>
                                <td>Indien geen <i>image</i> is opgegeven, wordt de eerste afbeelding uit deze lijst als <i>hero</i> gebruikt.</td>
                              </tr>
                            </table>
                        </div>
                        <h3>Optionele data</h3>
                        <p>Optioneel kan als merk tevens de itemcode en de advies-verkoop-prijs meegegeven worden. Deze data is handig voor retailers om eenvoudig en snel producten aan hun assortiment toe te voegen.</p>
                        <div className="table">
                            <table>
                              <tr>
                                <th></th>
                                <th>Type</th>
                                <th>Voorbeeld</th>
                                <th>Vereist</th>
                                <th>Opmerking</th>
                              </tr>
                              <tr>
                                <th>itemcode</th>
                                <td>string</td>
                                <td>12345-ABC</td>
                                <td>nee</td>
                                <td>unique internal identifier</td>
                              </tr>
                              <tr>
                                <th>price_currency</th>
                                <td>string</td>
                                <td>EUR</td>
                                <td>nee</td>
                                <td>ISO: EUR, USD, ...</td>
                              </tr>
                              <tr>
                                <th>price_in_cents</th>
                                <td>int</td>
                                <td>995</td>
                                <td>nee</td>
                                <td></td>
                              </tr>
                            </table>
                        </div>
                        <h3>Additionele informatie</h3>
                        <p>Voor producten van het type <i>Schoeisel</i> is het relevant extra informatie toe te voegen, zoals maat, kleur en of de zool los is. Hiervoor dienen per regel extra kolommen toegevoegd te worden, zoals hieronder te zien.</p>
                        <div className="table">
                            <table>
                              <tr>
                                <th></th>
                                <th>Type</th>
                                <th>Voorbeeld</th>
                                <th>Vereist</th>
                                <th>Opmerking</th>
                              </tr>
                              <tr>
                                <th>variation_id</th>
                                <td>string</td>
                                <td>123AB</td>
                                <td>nee</td>
                                <td>internal identifier per model</td>
                              </tr>
                              <tr>
                                <th>color_name</th>
                                <td>string</td>
                                <td>Zwartgrijs</td>
                                <td>nee</td>
                                <td>ISO (nl, de, fr, ...)</td>
                              </tr>
                              <tr>
                                <th>color</th>
                                <td>list</td>
                                <td>['black', 'grey']</td>
                                <td>nee</td>
                                <td>Deze data wordt gebruikt in de filtering voor de consument. opties: white, black, grey, cream, brown, purple, dark-blue, light-blue, green, yellow, orange, red, pink, multicolor</td>
                              </tr>
                              <tr>
                                <th>size</th>
                                <td>string</td>
                                <td>47</td>
                                <td>nee</td>
                                <td>vb: XS, M, 47, one-size, ...</td>
                              </tr>
                              <tr>
                                <th>demographic</th>
                                <td>list</td>
                                <td>['male', 'boys']</td>
                                <td>nee</td>
                                <td>men, women, unisex, boys, girls, juniors, toddlers, infants</td>
                              </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </section>

            <section className="text-default divider">
                <div className="inner">
                    <div className="text">
                        <h2>Diverse mogelijkheden</h2>
                        <p>Er zijn diverse mogelijkheden voor het koppelen van uw data. Uiteraard gaat de voorkeur uit naar een datakoppeling waarbij periodiek (bijvoorbeeld dagelijks) de data ververst kan worden. In het uiterste geval is het mogelijk om een eenmalige import te doen, zodat uw producten in het Zupr platform bekend zijn. Ook is het mogelijk om een koppeling te maken waarbij per verkooplocatie de voorraadinformatie automatisch verwerkt wordt.</p>
                        <p>Neem <a href="/contact">contact</a> op om te kijken welke optie voor u het meest van toepassing is.</p>
                    </div>
                </div>
            </section>

            <section className="contact-boost">
                <div className="inner">
                    <h2>Heeft u vragen?</h2>
                    <p>Voor vragen of toelichting kunt u contact opnemen met het Zupr team.</p>
                    <p>
                        <a href="mailto:support@zupr.nl?subject=Interesse in Zupr" className="email">support@zupr.nl</a>
                        <a href="tel:+31854011300" className="telephone">+31 (0)85 401 13 00</a>
                    </p>
                </div>
            </section>

    </Layout>
)

export default Page
